// font
@font-face {
  font-family: 'PingFangSC-Regular';
  src: url('../font/pingfang-regular-webfont.woff2') format('woff2'),
    url('../font/pingfang-regular-webfont.woff') format('woff'),
    url('../font/pingfang-regular-webfont.svg#pingfang_scregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PingFangSC-Medium';
  src: url('../font/pingfangsc-medium2-webfont.woff2') format('woff2'),
    url('../font/pingfangsc-medium2-webfont.woff') format('woff'),
    url('../font/pingfangsc-medium2-webfont.svg#pingfang_scmedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 14px;
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

body {
  font-family: Arial, PingFangSC-Medium !important;
}

// scroll bar
::-webkit-scrollbar-track-piece {
  // 滚动条凹槽的颜色，还可以设置边框属性
  background-color: #d8d8d8;
}
::-webkit-scrollbar {
  // 滚动条的宽度
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  // 滚动条的设置
  background-color: #aaa;
  background-clip: padding-box;
  min-height: 28px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}

// clear float
.clearfix {
  zoom: 1;
}
.clearfix:after {
  content: '';
  visibility: hidden;
  display: block;
  clear: both;
  overflow: hidden;
}
