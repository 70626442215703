// reset and common style
@import "./assets/style/base.scss";
@import "./assets/style/common.scss";
@font-face {
  font-family: AliHYAiHei;
  src: url("./assets/font/AliHYAiHei.ttf");
}
@font-face {
  font-family: Futura;
  src: url("./assets/font/Futura.ttf");
}
@font-face {
  font-family: CenturyGothic;
  src: url("./assets/font/CenturyGothic.ttf");
}
@font-face {
  font-family: CenturyGothicBold;
  src: url("./assets/font/CenturyGothicBold.ttf");
}
@font-face {
  font-family: Arial;
  src: url("./assets/font/Arial.woff2");
}

@font-face {
  font-family: font2;
  src: url("./assets/font/font2.woff2");
}

// ::-webkit-scrollbar {
//   width: 0px;
//   height: 0px;
// }
body {
  // user-select: none;
  font-family: Arial !important;
  .adm-picker-view-column-item-label {
    font-family: Mfa-Serif, Georgia, "Times New Roman", Times, serif;
  }
}

#root,
body,
html {
  min-height: 100% !important;
}
